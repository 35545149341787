// FerramentasDaListagem.tsx

import {
  Box,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';

// Foi importado o InputMask para aplicar as máscaras de CPF e CNPJ
import InputMask from 'react-input-mask';

interface IFerramentasDaListagemProps {
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
  aoClicarEmSearch?: () => void;
  isSearching?: boolean;
  onSelectSearchType?: (event: SelectChangeEvent<string>) => void;
  searchType?: string;
  handleKeyPress?: (event: React.KeyboardEvent) => void;
  onEnderecoChange?: (endereco: string) => void;
  onNumeroChange?: (numero: string) => void;
}

export const FerramentasDaListagem: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  aoMudarTextoDeBusca,
  mostrarInputBusca = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Nova',
  mostrarBotaoNovo = true,
  aoClicarEmSearch,
  onSelectSearchType,
  searchType,
  handleKeyPress,
  onEnderecoChange,
  onNumeroChange,
  isSearching = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  // Estados para busca por endereço e número
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');

  // Estado para controle do menu no mobile
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpenSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  // ✅ Função para bloquear caracteres que não sejam dígitos ou teclas de edição
  const handleNumberOnly = (event: React.KeyboardEvent<HTMLElement>) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Enter',
    ];

    // Permite colar com Ctrl+V, copiar com Ctrl+C, recortar com Ctrl+X e selecionar tudo com Ctrl+A
    if (
      (event.ctrlKey || event.metaKey) &&
      ['v', 'c', 'x', 'a'].includes(event.key.toLowerCase())
    ) {
      return;
    }
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  // Atualiza o campo de busca principal com endereço + número
  useEffect(() => {
    if (searchType === 'endereco') {
      const textoCombinado = `${endereco} ${numero}`.trim();
      if (textoCombinado !== textoDaBusca) {
        aoMudarTextoDeBusca?.(textoCombinado);
      }
    }
  }, [endereco, numero, searchType, aoMudarTextoDeBusca, textoDaBusca]);

  const SearchIcon = () => (
    isSearching ? <CircularProgress size={20} /> : <Icon>search</Icon>
  );

  return (
    <Box
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
      flexWrap="wrap"
      component={Paper}
    >
      {/* Box dos Inputs à esquerda */}
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        flexGrow={1}
        flexWrap="wrap"
      >
        {mostrarInputBusca && (
          <>
            {isMobile ? (
              <>
                {/* Botão para abrir Select no mobile */}
                <IconButton onClick={handleOpenSelect} size="small">
                  <Icon>arrow_drop_down</Icon>
                </IconButton>
                <Select
                  value={searchType}
                  onChange={(e) => {
                    onSelectSearchType?.(e);
                    handleCloseSelect();
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseSelect}
                  MenuProps={{
                    anchorEl,
                    open: Boolean(anchorEl),
                  }}
                  sx={{ display: 'none' }}
                >
                  <MenuItem value="codigo">Código</MenuItem>
                  <MenuItem value="cpf">CPF</MenuItem>
                  <MenuItem value="cnpj">CNPJ</MenuItem>
                  <MenuItem value="endereco">End + N°</MenuItem>
                </Select>
              </>
            ) : (
              <Select
                value={searchType}
                onChange={onSelectSearchType}
                size="small"
                sx={{ minWidth: '120px', maxWidth: '150px' }}
              >
                <MenuItem value="codigo">Código</MenuItem>
                <MenuItem value="cpf">CPF</MenuItem>
                <MenuItem value="cnpj">CNPJ</MenuItem>
                <MenuItem value="endereco">End + N°</MenuItem>
              </Select>
            )}

            {/* Se for busca por endereço, exibe dois campos */}
            {searchType === 'endereco' ? (
              <Box display="flex" gap={1} flexWrap="wrap">
                <TextField
                  size="small"
                  value={endereco}
                  placeholder="Endereço"
                  onKeyDown={(e) => {
                    handleKeyPress?.(e);
                  }}
                  onChange={(e) => {
                    setEndereco(e.target.value);
                    onEnderecoChange?.(e.target.value);  // Atualiza o estado no `EntitiesList.tsx`
                  }}
                  sx={{ width: isMobile ? '150px' : '200px' }}
                />
                <TextField
                  size="small"
                  value={numero}
                  placeholder="Nº"
                  onChange={(e) => {
                    setNumero(e.target.value);
                    onNumeroChange?.(e.target.value);  // Atualiza o estado no `EntitiesList.tsx`
                  }}
                  onKeyDown={(e) => {
                    handleNumberOnly(e);
                    handleKeyPress?.(e);
                  }} // Apenas dígitos
                  sx={{ width: isMobile ? '105px' : '125px' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => aoClicarEmSearch?.()}
                          size="small"
                          disabled={isSearching}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ) : (
              <>
                {searchType === 'cpf' && (
                  <InputMask
                    // Máscara de CPF
                    mask="999.999.999-99"
                    value={textoDaBusca}
                    onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
                  >
                    {() => (
                      <TextField
                        size="small"
                        placeholder="Digite o CPF"
                        onKeyDown={(e) => {
                          handleKeyPress?.(e);
                        }}
                        sx={{ width: isMobile ? '225px' : '300px' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => aoClicarEmSearch?.()}
                                size="small"
                                disabled={isSearching}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )}

                {searchType === 'cnpj' && (
                  <InputMask
                    // Máscara de CNPJ
                    mask="99.999.999/9999-99"
                    value={textoDaBusca}
                    onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
                  >
                    {() => (
                      <TextField
                        size="small"
                        placeholder="Digite o CNPJ"
                        onKeyDown={(e) => {
                          handleKeyPress?.(e);
                        }}
                        sx={{ width: isMobile ? '225px' : '300px' }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => aoClicarEmSearch?.()}
                                size="small"
                                disabled={isSearching}
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )}

                {/* Se for código, apenas bloqueia caracteres não numéricos */}
                {searchType === 'codigo' && (
                  <TextField
                    size="small"
                    value={textoDaBusca}
                    placeholder="Digite o código"
                    onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
                    onKeyDown={(e) => {
                      handleNumberOnly(e);
                      handleKeyPress?.(e);
                    }}
                    sx={{ width: isMobile ? '225px' : '300px' }}
                    inputProps={{ maxLength: 7 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => aoClicarEmSearch?.()}
                            size="small"
                            disabled={isSearching}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>

      {/* Botão NOVA sempre à direita */}
      {mostrarBotaoNovo && (
        <Button
          color="primary"
          disableElevation
          variant="contained"
          onClick={aoClicarEmNovo}
          endIcon={<Icon>add</Icon>}
          sx={{
            whiteSpace: 'nowrap',
            minWidth: '60px',
          }}
        >
          {!(isMobile && searchType === 'endereco') && textoBotaoNovo}
          {/* Se for mobile e a busca for "Rua + Nº", exibe apenas o ícone */}
        </Button>
      )}
    </Box>
  );
};
