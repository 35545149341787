// EntitiesService.ts

import { toast, ToastContainer } from 'react-toastify';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';
export interface IListagemPessoa {
  Tipo: string;
  StatusEntidade: string;
  CPFCNPJ: string;
  id: number;
  Codigo: string;
  DataCadastro: string;
  Nome: string;
}

interface ICategoria {
  Operacao: string;
  Codigo: string;
}

export interface IDetalheEntidade {
  Codigo: string | undefined;
  Nome: string;
  CodigoRegiao: string;
  Texto: string | null | undefined;
  Entidade1Object?: {
    DocEntidadeChildList?: Array<{
      Arquivo: string;
    }>;
  };
}

type TPessoasComTotalCount = {
  data: IListagemPessoa[];
  totalCount: number;
};

const getAll = async (
  page = 1,
  filter = ''
): Promise<TPessoasComTotalCount | Error> => {
  try {
    // const urlRelativa = `/entidades?_page=${page}&_limit=${Environment.LIMITE_DE_LINHAS}&nomeCompleto_like=${filter}`;
    const urlRelativa = `/entities?filter=Nome+Like'%${filter}%'`;
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error: any) {
    console.error(error.response?.data?.message);

    toast.error(error.response?.data?.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return new Error(
      (error as { message: string }).message || 'Erro ao listar os registros.'
    );
  }
};

const getByDocument = async (
  filter = ''
): Promise<TPessoasComTotalCount | Error> => {
  try {
    // const urlRelativa = `/entidades?_page=${page}&_limit=${Environment.LIMITE_DE_LINHAS}&nomeCompleto_like=${filter}`;
    const urlRelativa = `/entities?filter=CPFCNPJ=${filter}`;
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error: any) {
    console.error(error.response?.data?.message);

    toast.error(error.response?.data?.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return new Error(
      (error as { message: string }).message || 'Erro ao listar os registros.'
    );
  }
};

const getEntityListByCode = async (
  filter = ''
): Promise<TPessoasComTotalCount | Error> => {
  try {
    // const urlRelativa = `/entidades?_page=${page}&_limit=${Environment.LIMITE_DE_LINHAS}&nomeCompleto_like=${filter}`;
    const urlRelativa = `/entities?filter=Codigo=${filter}`;
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error: any) {
    console.error(error.response?.data?.message);

    toast.error(error.response?.data?.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return new Error(
      (error as { message: string }).message || 'Erro ao listar os registros.'
    );
  }
};

const getByAddress = async (
  endereco = '',
  numero = ''
): Promise<TPessoasComTotalCount | Error> => {
  try {
    // Função para capitalizar a primeira letra de cada palavra
    const capitalizeFirstLetter = (str: string) => {
      return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    };

    const enderecoCapitalizado = capitalizeFirstLetter(endereco);
    const enderecoNormalizado = endereco.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    const urlRelativa = `/entities?filter=${encodeURIComponent(`(
      Endereco Like '%${endereco}%' or 
      Endereco Like '%${endereco.toLowerCase()}%' or 
      Endereco Like '%${endereco.toUpperCase()}%' or
      Endereco Like '%${enderecoNormalizado}%' or
      Endereco Like '%${enderecoNormalizado.toUpperCase()}%' or
      Endereco Like '%${enderecoCapitalizado}%'
    ) AND NumeroEndereco=='${numero}'`)}`;
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error: any) {
    console.error(error.response?.data?.message);

    toast.error(error.response?.data?.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return new Error(
      (error as { message: string }).message || 'Erro ao listar os registros.'
    );
  }
};

const getById = async (code: string): Promise<IDetalheEntidade | Error> => {
  try {
    const { data } = await Api.get(`/entities/${code}`);
    const entidade = data; // TODO: Arrumar na API essa redundância data.data

    console.log('Detalhe da entidade', entidade);
    if (entidade) {
      return entidade;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao consultar o registro.'
    );
  }
};

const create = async (
  dados: Omit<IDetalheEntidade, 'Codigo'>
): Promise<string | Error> => {
  try {
    const { data } = await Api.post<IDetalheEntidade>('/entities', dados);


    // Se não retornou Código, erro
    if (!data?.Codigo) {
      return new Error('Erro ao criar o registro: Código não retornado.');
    }
    await Api.post<IDetalheEntidade>('/entities/savePartialData', {
      Codigo: data?.Codigo,
      Texto: dados?.Texto,
    });

    if (data && data.Codigo) {
      //return data.id;
      return data.Codigo;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error('ERROR', error);
    return new Error(
      (error as { message: string }).message || 'Erro ao criar o registro.'
    );
  }
};

const update = async (dados: IDetalheEntidade): Promise<string | Error> => {
  try {
    const { data } = await Api.post<IDetalheEntidade>('/entities/edit', dados);


    // Se não retornou Código, erro
    if (!data?.Codigo) {
      return new Error('Erro ao atualizar o registro: Código não retornado.');
    }

    await Api.post<IDetalheEntidade>('/entities/savePartialData', {
      Codigo: dados?.Codigo,
      Texto: dados?.Texto,
    });


    if (data && data.Codigo) {
      //return data.id;
      return data.Codigo; // TODO: Verificar, pois acredito que data todo tem que ser retornado, para captura correta dos erros.
    }

    return new Error('Erro ao atualizar o registro.');
  } catch (error) {
    console.error('ERROR', error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};

/*
const updateByCode = async (
  Codigo: string,
  dados: IDetalheEntidade
): Promise<void | Error> => {
  try {
    await Api.post(`/pessoas/${Codigo}`, dados);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao atualizar o registro.'
    );
  }
};*/

/*
const deleteById = async (id: number): Promise<void | Error> => {
  try {
    await Api.delete(`/pessoas/${id}`);
  } catch (error) {
    console.error(error);
    return new Error(
      (error as { message: string }).message || 'Erro ao apagar o registro.'
    );
  }
};*/

const verifyEntityDuplicity = async (
  documento = ''
): Promise<TPessoasComTotalCount | Error> => {
  try {
    const urlRelativa = `/entities?filter=${encodeURIComponent(`CPFCNPJ=='${documento}' AND CodigoStatEnt!='10'`)}`;
    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(
          headers['x-total-count'] || Environment.LIMITE_DE_LINHAS
        ),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error: any) {
    console.error(error.response?.data?.message);

    toast.error(error.response?.data?.message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    return new Error(
      (error as { message: string }).message || 'Erro ao listar os registros.'
    );
  }
};

export const EntitiesService = {
  getAll,
  getByDocument,
  getEntityListByCode,
  getByAddress,
  create,
  getById,
  update,
  verifyEntityDuplicity
  //deleteById,
};
