// RTextField.tsx

import { Controller, useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import InputMask from 'react-input-mask';
import { useState } from 'react';

// Função para remover a máscara
const removeMask = (value: string) => value.replace(/\D/g, ''); // Remove tudo que não for número

// Com TextFieldProps eu digo que ele (RTextField) pode receber todas as propriedades do textField do MUI, mais outras que podemos adicionar, por isso o uso do & abaixo
type TVTextFieldProps = TextFieldProps & {
  name: string;
  mask?: string; // Propriedade opcional para a máscara
  uppercase?: boolean; // Propriedade opcional para converter para maiúsculas
  disabledValue?: boolean;
  onAdornmentClick?: () => void; // Função opcional para clique no adorno (ex: botão de busca)
  adornmentIcon?: React.ReactNode; // Ícone opcional para o adorno
  validateAsync?: (value: string) => Promise<string | null>; // 🔥 Função de validação dinâmica
  onChangeCapture?: () => void;
};






// Uso do ...rest por causa do textFieldProps, propriedades padroes que estão sendo passadas
export const RTextField: React.FC<TVTextFieldProps> = ({ name, mask, uppercase = false, disabledValue, onAdornmentClick,
  adornmentIcon, validateAsync , ...rest }) => {
  const { control, setError, clearErrors, formState: { errors } } = useFormContext();
  const [isChecking, setIsChecking] = useState(false);

  const handleBlur = async (value: string) => {
    const cleanValue = removeMask(value); // 🔥 Removemos a máscara aqui
    if (validateAsync) {
      setIsChecking(true);
      const errorMessage = await validateAsync(cleanValue);
      setIsChecking(false);
  
      if (errorMessage) {
        setError(name, { type: 'manual', message: errorMessage });
      } else {
        clearErrors(name);
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        // Função para lidar com a remoção de acentos e transformação para maiúsculas
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let value = e.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
          if (uppercase) {
            value = value.toUpperCase();
          }
          field.onChange(value);
        };

        const inputAdornment = adornmentIcon && (
          <InputAdornment position="end">
            <IconButton onClick={onAdornmentClick}>{adornmentIcon}</IconButton>
          </InputAdornment>
        );

        return mask ? (
          // Componente com máscara
          <InputMask
            mask={mask}
            value={field.value || ''}
            onChange={handleChange}
            disabled={rest.disabled}
            onBlur={(e) => {
              field.onBlur();
              handleBlur(e.target.value);
            }}
          >
            {(inputProps: TextFieldProps) => (
              <TextField
                {...inputProps}
                {...rest}
                slotProps={{
                  input: {
                    endAdornment: inputAdornment, // Adiciona o botão de busca como adorno
                    readOnly: disabledValue, // Define o campo como somente leitura
                  },
                }}
                error={!!errors[name]}
                helperText={errors[name]?.message ? String(errors[name]?.message) : ''}
                fullWidth
                disabled={disabledValue}
                sx={{
                  '& .MuiFormHelperText-root': {
                    width: '100%',
                    minWidth: '300px',
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            )}
          </InputMask>
        ) : (
          // Componente sem máscara
          <TextField
            {...field}
            {...rest}
            value={field.value || ''}
            onChange={handleChange}
            slotProps={{
              input: {
                endAdornment: inputAdornment, // Adiciona o botão de busca como adorno
              },
            }}
            error={!!errors[name]}
            helperText={errors[name]?.message ? String(errors[name]?.message) : ''}
            fullWidth
            disabled={disabledValue}
            sx={{
              '& .MuiFormHelperText-root': {
                width: '100%',
                minWidth: '300px',
                whiteSpace: 'nowrap',
              },
            }}
          />
        );
      }}
    />
  );
};


