// EntitiesList.tsx

import { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Icon,
  IconButton,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import {
  IListagemPessoa,
  EntitiesService,
} from '../../shared/services/api/entities/EntitiesService';
import { FerramentasDaListagem } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { useDebounce } from '../../shared/hooks';
import { Environment } from '../../shared/environment';

const formatCPFCNPJ = (value: string): string => {
  const cleanValue = value.replace(/\D/g, '');
  return cleanValue.length === 11
    ? cleanValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    : cleanValue.length === 14
      ? cleanValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
      : value;
};

export const EntitiesList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchType, setSearchType] = useState('codigo');
  const [rows, setRows] = useState<IListagemPessoa[]>([]);
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const busca = useMemo(() => searchParams.get('busca') || '', [searchParams]);
  const pagina = useMemo(
    () => Number(searchParams.get('pagina') || '1'),
    [searchParams]
  );

  const [tempBusca, setTempBusca] = useState(busca);
  const [isSearching, setIsSearching] = useState(false);

  const validateSearch = (texto: string): boolean => {
    if (!texto.trim()) return false;

    if (searchType === 'codigo' && texto.length !== 7) {
      toast.warn('Código deve ter exatamente 7 caracteres.');
      return false;
    }

    if (['cpf', 'cnpj'].includes(searchType)) {
      const cleanBusca = texto.replace(/\D/g, '');
      if (
        (searchType === 'cpf' && cleanBusca.length !== 11) ||
        (searchType === 'cnpj' && cleanBusca.length !== 14)
      ) {
        toast.warn(
          `${searchType.toUpperCase()} deve ter ${
            searchType === 'cpf' ? '11' : '14'
          } dígitos.`
        );
        return false;
      }
    }

    return true;
  };

  const fetchData = useCallback(async () => {
    if (!validateSearch(tempBusca)) return;
  
    setIsLoading(true);
    setIsSearching(true);
    
    try {
      const cleanBusca = tempBusca.replace(/\D/g, '');
      setRows([]);
  
      let result: Awaited<ReturnType<typeof EntitiesService.getByDocument>> | null = null;

      if (searchType === 'codigo') {
        result = await EntitiesService.getEntityListByCode(tempBusca);
      } else if (searchType === 'cpf' || searchType === 'cnpj') {
        result = await EntitiesService.getByDocument(cleanBusca);
      } else if (searchType === 'endereco') {
        result = await EntitiesService.getByAddress(endereco, numero);
      }

      if (result && !(result instanceof Error)) {
        if (result.data.length === 0) {
          toast.warn('Registro não encontrado.');
          setRows([]);
          setTotalCount(0);
        } else {
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      } else if (result instanceof Error) {
        console.error(result.message);
      }
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  }, [tempBusca, searchType]);
  

  const handleChangeBusca = (texto: string) => {
    setTempBusca(texto.trim());
    if (!texto.trim()) {
      setRows([]);
      setTotalCount(0);
    }
  };

  const handleSearchTypeChange = (event: SelectChangeEvent) => {
    setSearchType(event.target.value);
    setTempBusca('');
    setRows([]);
    setTotalCount(0);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      fetchData();
    }
  };

  const handleSearch = async () => {
    setSearchParams({ busca: tempBusca, pagina: '1' }, { replace: true });
    await fetchData();
  };

  return (
    <LayoutBaseDePagina
      titulo="Entidades Prospects"
      barraDeFerramentas={
        <FerramentasDaListagem
          mostrarInputBusca
          textoDaBusca={tempBusca}
          textoBotaoNovo="Nova"
          aoClicarEmNovo={() => navigate('/entidades/detalhe/nova')}
          aoMudarTextoDeBusca={handleChangeBusca}
          aoClicarEmSearch={handleSearch}
          isSearching={isSearching}
          onSelectSearchType={handleSearchTypeChange}
          searchType={searchType}
          handleKeyPress={handleKeyPress}
          onEnderecoChange={setEndereco}
          onNumeroChange={setNumero}
        />
      }
    >
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ m: 1, width: 'auto' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ação</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Data Cadastro</TableCell>
              <TableCell>Nome</TableCell>
              {isDesktop && (
                <>
                  <TableCell>CNPJ/CPF</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tipo</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.Codigo}>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/entidades/detalhe/${row.Codigo}`)}
                  >
                    <Icon>edit</Icon>
                  </IconButton>
                </TableCell>
                <TableCell>{row.Codigo}</TableCell>
                <TableCell>
                  {moment(row.DataCadastro).format('DD/MM/YY')}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: isDesktop ? 200 : 120,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {row.Nome}
                </TableCell>
                {isDesktop && (
                  <>
                    <TableCell>{formatCPFCNPJ(row.CPFCNPJ)}</TableCell>
                    <TableCell>{row.StatusEntidade}</TableCell>
                    <TableCell>{row.Tipo}</TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={isDesktop ? 7 : 4}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}

            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={isDesktop ? 7 : 4}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) =>
                      setSearchParams(
                        { busca, pagina: newPage.toString() },
                        { replace: true }
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
