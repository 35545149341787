// EntitiesDetail.tsx

import RFileUpload from '../../shared/components/work-order/RFileUpload'; // Importe o novo componente

import React, { useState, useEffect } from 'react';
import { Box, Grid2, LinearProgress, Paper, Typography, CircularProgress, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastContainer, toast } from 'react-toastify';
import _, { toUpper } from 'lodash';
import SearchIcon from '@mui/icons-material/Search'; // Ícone de busca do Material-UI
import { cpf, cnpj } from 'cpf-cnpj-validator';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FerramentasDeDetalhe } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { EntitiesService } from '../../shared/services/api/entities/EntitiesService';
import { RAutoComplete } from '../../shared/forms/RAutoComplete';
import { RTextField } from '../../shared/forms/RTextField';
import { RSelect } from '../../shared/forms';
import { RAccordion } from '../../shared/forms/RAccordion';
import { RRadio } from '../../shared/forms/RRadio';
import { useDebounce } from '../../shared/hooks';
import { CitiesService } from '../../shared/services/api/cities/CitiesService';
import { AddressService } from '../../shared/services/api/address/AddressService';
import './styles.css'; // Importando o CSS customizado

// Definir o schema Yup para o formulário
const formValidationSchema = yup.object({
  Nome: yup
    .string()
    .required('Nome/Razão é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(100, 'Máximo de 100 caracteres permitidos.'),
  CodigoRegiao: yup.string().required('Código da região é obrigatório.'),
  CaracteristicaImovel: yup
    .number()
    .nullable()
    .transform((value, originalValue) =>
      String(originalValue).trim() === '' ? null : value
    )
    .typeError('Selecione uma característica válida')
    .required('Característica do imóvel é obrigatória'),
  Cep: yup.string().nullable(),
  Logradouro: yup.string().nullable(),
  Endereco: yup
    .string()
    .max(40, 'Máximo de 40 caracteres permitidos.')
    .nullable(),
  NumeroEndereco: yup
    .string()
    .max(6, 'Máximo de 6 caracteres permitidos.')
    .nullable(),
  Bairro: yup
    .string()
    .max(30, 'Máximo de 30 caracteres permitidos.')
    .nullable(),
  ComplementoEndereco: yup
    .string()
    .max(40, 'Máximo de 40 caracteres permitidos.')
    .nullable(),
  CodigoCidade: yup.string().nullable(),
  Categorias: yup.array().of(
    yup.object({
      Operacao: yup.string().nullable(),
      Codigo: yup.string().required('Ao menos 1 categoria é obrigatória.'),
    })
  ),
  TipoFisicaJuridica: yup
    .string()
    .oneOf(['Física', 'Jurídica'], 'Selecione um tipo válido.')
    .required('O tipo é obrigatório.'),
  CPFCNPJ: yup
    .string()
    .required('O CPF/CNPJ é obrigatório.')
    .test('valid-cpf-cnpj', 'CPF ou CNPJ inválido.', (value, context) => {
      const tipo = context.parent.TipoFisicaJuridica; // Obtém o tipo do formulário
      if (!value) return false;

      // Validação dinâmica
      return tipo === 'Física' ? cpf.isValid(value) : cnpj.isValid(value);
    }),
  RGIE: yup.string().nullable(),
  NomeFantasia: yup
    .string()
    .nullable()
    .max(40, 'Máximo de 40 caracteres permitidos.'),
  /*Enderecos: yup.array().of(
    yup.object({
      Cep: yup.string(),
      Logradouro: yup.string(),
      Endereco: yup.string().max(40, 'Máximo de 40 caracteres permitidos.'),
      NumeroEndereco: yup.string().max(6, 'Máximo de 6 caracteres permitidos.'),
      Bairro: yup.string().max(30, 'Máximo de 30 caracteres permitidos.'),
      ComplementoEndereco: yup
        .string()
        .max(40, 'Máximo de 40 caracteres permitidos.'),
      CodigoCidade: yup.string(),
    })
  ),*/
  Telefones: yup.array().of(
    yup.object({
      Operacao: yup.string().nullable(),
      Tipo: yup.string().nullable(),
      DDD: yup.string().nullable(),
      Numero: yup.string().nullable(),
      NumeroRamal: yup.string().nullable(),
      Principal: yup.string().nullable(),
      Descricao: yup.string().nullable(),
    })
  ),
  Emails: yup.array().of(
    yup.object({
      Operacao: yup.string().nullable(),
      Tipo: yup.string().nullable(),
      Email: yup.string().nullable(),
      Principal: yup.string().nullable(),
      NFe: yup.string().nullable(),
      NFSe: yup.string().nullable(),
      Descricao: yup.string().nullable(),
    })
  ),
  CodigoStatus: yup.string(),
  DataCadastro: yup.string(),
  // Validação condicional para o campo Codigo
  Codigo: yup.string().when('$isEdicao', {
    is: true, // Se estamos no modo edição
    then: yup.string().required('O Código do registro é obrigatório.'), // Código obrigatório
    otherwise: yup.string().nullable(), // Código opcional
  }),
  Texto: yup.string().nullable(),
  Documentos: yup.array().of(
    yup.object({
      id: yup.string().required(),
      Arquivo: yup.string().required(),
      ArquivoBase64: yup.string().required(),
      Operacao: yup.string().oneOf(['I']).required(),
    })
  ).required().default([]),
});

type IFormData = yup.InferType<typeof formValidationSchema>;

// Tipo do item do array `addresses`
// type AddressFieldValues = NonNullable<IFormData['Enderecos']>[number];
type PhoneFieldValues = NonNullable<IFormData['Telefones']>[number];
type EmailFieldValues = NonNullable<IFormData['Emails']>[number];

const phoneFieldConfig: {
  id: keyof PhoneFieldValues;
  label: string;
  type?: string;
  component?: React.FC<any>;
  extraProps?: Record<string, any>;
}[] = [
  {
    id: 'Tipo',
    label: 'Tipo',
    type: 'text',
    component: RSelect,
    extraProps: {
      options: [
        { value: 'Celular', label: 'Celular' },
        { value: 'Comercial', label: 'Comercial' },
        { value: 'Residencial', label: 'Residencial' },
        { value: 'Recado', label: 'Recado' },
      ],
    },
  },
  { id: 'DDD', label: 'DDD', type: 'number' },
  { id: 'Numero', label: 'Número', type: 'text' },
  { id: 'NumeroRamal', label: 'Ramal', type: 'text' },
  { id: 'Descricao', label: 'Descrição', type: 'text' },
  {
    id: 'Principal',
    label: 'Principal',
    component: RRadio,
    extraProps: {
      options: [
        { value: 'Sim', label: 'Sim' },
        { value: 'Não', label: 'Não' },
      ],
    },
  }
];

const emailFieldConfig: {
  id: keyof EmailFieldValues;
  label: string;
  type?: string;
  component?: React.FC<any>;
  extraProps?: Record<string, any>;
}[] = [
  {
    id: 'Tipo',
    label: 'Tipo',
    type: 'text',
    component: RSelect,
    extraProps: {
      options: [
        { value: 'Comercial', label: 'Comercial' },
        { value: 'Financeiro', label: 'Financeiro' },
        { value: 'Pessoal', label: 'Pessoal' },
        { value: 'Web', label: 'Web' },
        { value: 'Outros', label: 'Outros' },
      ],
    },
  },
  { id: 'Email', label: 'Email', type: 'text' },
  { id: 'Descricao', label: 'Descrição', type: 'text' },
  {
    id: 'Principal',
    label: 'Principal',
    component: RRadio,
    extraProps: {
      options: [
        { value: 'Sim', label: 'Sim' },
        { value: 'Não', label: 'Não' },
      ],
    },
  },
  {
    id: 'NFe',
    label: 'NFe',
    component: RRadio,
    extraProps: {
      options: [
        { value: 'Sim', label: 'Sim' },
        { value: 'Não', label: 'Não' },
      ],
    },
  },
  {
    id: 'NFSe',
    label: 'NFSe',
    component: RRadio,
    extraProps: {
      options: [
        { value: 'Sim', label: 'Sim' },
        { value: 'Não', label: 'Não' },
      ],
    },
  },
];

type CategoriesFieldValues = NonNullable<IFormData['Categorias']>[number];

const categoriesFieldConfig: {
  id: keyof CategoriesFieldValues;
  label: string;
  type?: string;
  component?: React.FC<any>;
  extraProps?: Record<string, any>;
}[] = [
  {
    id: 'Codigo',
    label: 'Categoria da Entidade',
    component: RAutoComplete,
    extraProps: {
      source: 'CategoriasService', // Especifica o serviço
    },
  },
];


export const EntitiesDetail: React.FC = () => {
  const { id = 'nova' } = useParams<'id'>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingCNPJ, setIsFetchingCNPJ] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [documentoVerificado, setDocumentoVerificado] = useState<string | null>(null);
  const [nome, setNome] = useState('');
  const { debounce } = useDebounce();
  const [arquivosAnexados, setArquivosAnexados] = useState<string[]>([]);


  const [deletedItems, setDeletedItems] = useState<{
    Categorias: CategoriesFieldValues[];
    // Enderecos: AddressFieldValues[];
    Telefones: PhoneFieldValues[];
    Emails: EmailFieldValues[];
  }>({
    Categorias: [],
    // Enderecos: [],
    Telefones: [],
    Emails: [],
  });

  const hasCodigo = (
    item: CategoriesFieldValues | undefined
  ): item is CategoriesFieldValues & { Codigo: string } => {
    return !!item?.Codigo;
  };

  const onRemoveCategory = (index: number) => {
    const itemToRemove = categoryFields[index]; // Acesso ao item no array

    if (hasCodigo(itemToRemove)) {
      // Garantir que o item tem `Codigo`
      setDeletedItems((prev) => ({
        ...prev,
        Categorias: [
          ...prev.Categorias,
          {
            ...itemToRemove,
            Operacao: 'E', // Adiciona a operação de exclusão
          },
        ],
      }));
    }

    removeCategory(index); // Remove o item do formulário
  };

  const hasNumero = (
    item: PhoneFieldValues | undefined
  ): item is PhoneFieldValues & { Numero: string } => {
    return !!item?.Numero;
  };

  const onRemovePhone = (index: number) => {
    const itemToRemove = phoneFields[index];

    if (hasNumero(itemToRemove)) {
      setDeletedItems((prev) => ({
        ...prev,
        Telefones: [
          ...prev.Telefones,
          { ...itemToRemove, Operacao: 'E' } as PhoneFieldValues, // Define operação de exclusão
        ],
      }));
    }

    removePhone(index); // Remove do formulário
  };

  const hasEmail = (
    item: EmailFieldValues | undefined
  ): item is EmailFieldValues & { Email: string } => {
    return !!item?.Email;
  };

  const onRemoveEmail = (index: number) => {
    const itemToRemove = emailFields[index];

    if (hasEmail(itemToRemove)) {
      setDeletedItems((prev) => ({
        ...prev,
        Emails: [
          ...prev.Emails,
          { ...itemToRemove, Operacao: 'E' } as EmailFieldValues, // Define operação de exclusão
        ],
      }));
    }

    removeEmail(index); // Remove do formulário
  };

  const methods = useForm<IFormData>({
    resolver: yupResolver(formValidationSchema, {
      context: { isEdicao: id !== 'nova' },
    }),
    mode: 'onSubmit',
    defaultValues: {
      Nome: '',
      CaracteristicaImovel: undefined, // Controlado globalmente e não apenas no componente
      Categorias: [{ Codigo: '', Operacao: 'I' }], // Definir valor padrão para Categorias
      CPFCNPJ: '',
      TipoFisicaJuridica: 'Jurídica',
      CodigoRegiao: 'CO',
      CodigoCidade: '',
      Cep: '',
      CodigoStatus: '06',
      Documentos: [], // Adiciona Documentos como array vazio
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = methods; // Retorna todos os métodos do useForm

  const isValidKey = (key: string, obj: object): key is keyof typeof obj => {
    return key in obj;
  };

  useEffect(() => {
    if (id !== 'nova') {
      setIsLoading(true);
      EntitiesService.getById(id).then(async (result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
          navigate('/entidades');
        } else {
          setNome(result.Nome);

          console.log('Resposta da API:', result); // Log para verificar o retorno da API

          // Processar os arquivos anexados
          const documentos = result.Entidade1Object?.DocEntidadeChildList;

          if (documentos && Array.isArray(documentos)) {
            const arquivosExtraidos = documentos.map((doc: any) => {
              if (doc.Arquivo) {
                const partes = doc.Arquivo.split(/[/\\]/); // Divide em qualquer tipo de barra
                return partes.length > 2 ? partes[partes.length - 1] : doc.Arquivo;
              }
              return 'Arquivo não disponível';
            });

            console.log('Arquivos extraídos:', arquivosExtraidos); // Log para debug
            setArquivosAnexados(arquivosExtraidos);
          } else {
            console.log('DocEntidadeChildList está vazia ou não encontrada.');
            setArquivosAnexados([]);
          }

          // Obter as chaves válidas do schema
          const schemaKeys = Object.keys(
            formValidationSchema.describe().fields
          );

          // Atualizar apenas as chaves válidas
          schemaKeys.forEach((key) => {
            if (isValidKey(key, result)) {
              setValue(key as keyof IFormData, result[key]); // Armazena apenas o ID no formulário
            }
          });
        }
      });
    }
  }, [id, navigate, setValue]);

  // useFieldArray para gerenciar dinamicamente os campos de Categorias
  const {
    fields: categoryFields,
    append: appendCategory,
    remove: removeCategory,
  } = useFieldArray<IFormData, 'Categorias'>({
    control,
    name: 'Categorias',
  });

  // useFieldArray para gerenciar dinamicamente os campos de Endereços
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray<IFormData, 'Telefones'>({
    control,
    name: 'Telefones',
  });

  // useFieldArray para gerenciar dinamicamente os campos de Endereços
  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray<IFormData, 'Emails'>({
    control,
    name: 'Emails',
  });

  // Função para transformar valores null em ""
  const transformNullToEmptyString = (obj: any): any => {
    if (Array.isArray(obj)) {
      // Caso seja um array, aplica a transformação em cada item
      return obj.map((item) => transformNullToEmptyString(item));
    } else if (obj !== null && typeof obj === 'object') {
      // Caso seja um objeto, aplica a transformação em cada propriedade
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
     
          key,
          value === null ? '' : transformNullToEmptyString(value),
        ])
      );
    }
    return obj; // Retorna o valor original se não for objeto nem array
  };

  // console.log('addressFields', addressFields);
  const onSubmit = (dados: IFormData) => {
    console.log('INIT ONSUBMIT', 'INIT ONSUBMIT');

    // Verifica se há documentos anexados e se algum ultrapassa 20MB
    const documentosInvalidos = dados.Documentos?.some(doc => {
      const fileSizeMB = (doc.ArquivoBase64.length * (3 / 4)) / (1024 * 1024); // Aproximando o tamanho original do arquivo
      return fileSizeMB > 20;
    });

    if (documentosInvalidos) {
      toast.error('Existe(m) arquivo(s) que ultrapassa(m) 20 MB. Remova-os antes de continuar.');
      return;
    }


    // Transforma os valores null para strings vazias
    const dadosTransformados = transformNullToEmptyString(dados);

    // Verifique se Categorias está definido
    const categoriasComOperacao = dadosTransformados.Categorias
      ? dadosTransformados.Categorias.map((categoria: any) => ({
        ...categoria,
        Operacao: 'I', // Define 'I' para cada categoria
      }))
      : [];

    const telefonesComOperacao = dadosTransformados.Telefones
      ? dadosTransformados.Telefones.map((telefone: any) => ({
        ...telefone,
        Operacao: 'I', // Define 'I' para cada telefone
      }))
      : [];

    const emailsComOperacao = dadosTransformados.Emails
      ? dadosTransformados.Emails.map((email: any) => ({
        ...email,
        Operacao: 'I', // Define 'I' para cada email
      }))
      : [];

    // Verifica se há documentos anexados e garante que estejam no formato correto
    const documentosComOperacao = dadosTransformados.Documentos?.map((doc: { ArquivoBase64: string; Operacao: string; Arquivo: string; }) => ({
      Operacao: 'I',
      Arquivo: doc.Arquivo, // Nome do arquivo
      ArquivoBase64: doc.ArquivoBase64, // Base64 do arquivo
    })) || [];

    if (!dadosTransformados.Codigo) {
      // TODO: Quando fornecedor arrumar endpoint arrumar esse bug
      delete dadosTransformados.Codigo; // Remove a propriedade se for "falsy"
    }

    // Atualiza os dados com Categorias modificadas
    const dadosAtualizados = {
      ...dadosTransformados,
      Categorias: [...categoriasComOperacao, ...deletedItems.Categorias],
      Telefones: [...telefonesComOperacao, ...deletedItems.Telefones],
      Emails: [...emailsComOperacao, ...deletedItems.Emails],
      Documentos: documentosComOperacao,
    };

    console.log('Dados enviados ao back-end:', dadosAtualizados);

    setIsSaving(true);

    if (id === 'nova') {
      EntitiesService.create(dadosAtualizados).then((result) => {
        setIsSaving(false);
        if (result instanceof Error) {
          console.log('erro', result.message);
        } else {
          toast.success('Cadastro salvo com sucesso!', { autoClose: 3000 });
          navigate('/entidades');
        }
      });
    } else {
      // Edição de uma entidade existente
      EntitiesService.update(dadosAtualizados).then((result) => {
        setIsSaving(false);

        if (result instanceof Error) {
          console.error('Erro ao editar entidade:', result.message);
          toast.error('Erro ao salvar alterações. Por favor, tente novamente.');
        } else {
          toast.success('Alterações salvas com sucesso!', { autoClose: 3000 });
          navigate('/entidades');
        }
      });
    }
  };

  // Usar o watch para monitorar o valor do campo 'tipo'
  const tipoSelecionado = watch('TipoFisicaJuridica') as Tipo;

  type Tipo = 'Física' | 'Jurídica';
  const typeEntityConfig: Record<
    Tipo,
    {
      mascara: string;
      labelCPFCNPJ: string;
      labelRGIE: string;
      labelNomeRazao: string;
      zipeCode: string;
    }
  > = {
    Física: {
      mascara: '999.999.999-99',
      labelCPFCNPJ: 'CPF',
      labelRGIE: 'RG',
      labelNomeRazao: 'Nome Completo',
      zipeCode: '99999-999',
    },
    Jurídica: {
      mascara: '99.999.999/9999-99',
      labelCPFCNPJ: 'CNPJ',
      labelRGIE: 'IE',
      labelNomeRazao: 'Razão Social',
      zipeCode: '99999-999',
    },
  };
  // Função para obter a máscara e o label
  const getTypeEntityConfig = (tipo: Tipo) =>
    typeEntityConfig[tipo] || { mascara: '', label: '' };
  // Uso:
  const { mascara, labelCPFCNPJ, labelRGIE, labelNomeRazao, zipeCode } =
    getTypeEntityConfig(tipoSelecionado);

  const buscarDadosCNPJ = async () => {
    const cnpjNumero = methods.getValues('CPFCNPJ')?.replace(/\D/g, ''); // Remove caracteres especiais

    if (!cnpjNumero || cnpjNumero.length !== 14) {
      toast.error('Por favor, insira um CNPJ válido (14 dígitos).');
      return;
    }

    const nomeEmpresa = methods.getValues('Nome') || ''; // Obtém o nome salvo
    if (nomeEmpresa.length > 1) {
      const confirmacao = window.confirm(
        'Os dados existentes serão sobrescritos. Deseja continuar?'
      );
      if (!confirmacao) {
        return;
      }
    }

    try {
      setIsLoading(true); // Ativa o loading

      const response = await fetch(
        `https://publica.cnpj.ws/cnpj/${cnpjNumero}`
      );

      if (response.status === 429) {
        toast.error(
          'Limite de consultas atingido. Aguarde um minuto antes de tentar novamente.'
        );
        setIsLoading(false);
        return;
      }

      if (!response.ok) throw new Error('Erro ao buscar CNPJ.');

      const data = await response.json();

      console.log('data', data);

      if (data?.razao_social) {
        if (toUpper(data.estabelecimento?.situacao_cadastral) !== 'ATIVA') {
          toast.warning('A empresa consultada está inativa.');
          setIsLoading(false);
          return;
        }

        // Filtrar apenas as inscrições estaduais ativas
        const inscricoesAtivas =
          data.estabelecimento?.inscricoes_estaduais?.filter(
            (ie: { inscricao_estadual: string; ativo: boolean }) =>
              ie.ativo === true
          ) || [];

        if (inscricoesAtivas.length > 1) {
          toast.warning(
            'Mais de uma inscrição estadual ativa encontrada. Consulte o fiscal e insira manualmente.'
          );
        }

        limparFormulario('CNPJ'); // Limpa os campos antes de inserir novos dados
        preencherFormularioComDadosCNPJ(data, inscricoesAtivas);
        toast.success('Dados do CNPJ preenchidos com sucesso!', {
          autoClose: 2000,
        });
      } else {
        toast.warning('CNPJ não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar CNPJ:', error);
      toast.error('Erro ao buscar os dados do CNPJ.');
    } finally {
      setIsLoading(false); // Finaliza o loading
    }
  };

  const limparFormulario = (tipo: 'CNPJ' | 'CEP') => {
    if (tipo === 'CNPJ') {
      const cnpjAtual = methods.getValues('CPFCNPJ');
      const tipoSelecionado = methods.getValues('TipoFisicaJuridica');
      methods.reset({
        CPFCNPJ: cnpjAtual, // Mantém o CNPJ preenchido
        TipoFisicaJuridica: tipoSelecionado, // Mantém o tipo da entidade
        Nome: '',
        NomeFantasia: '',
        RGIE: '',
        Cep: '',
        Endereco: '',
        NumeroEndereco: '',
        Bairro: '',
        ComplementoEndereco: '',
        CodigoCidade: '',
        Telefones: [],
        Emails: [],
      });
    }

    if (tipo === 'CEP') {
      methods.setValue('Logradouro', '');
      methods.setValue('Endereco', '');
      methods.setValue('NumeroEndereco', '');
      methods.setValue('Bairro', '');
      methods.setValue('ComplementoEndereco', '');
      methods.setValue('CodigoCidade', '');
    }
  };

  
  type InscricaoEstadual = {
    inscricao_estadual: string;
  };
  
  type Cidade = {
    ibge_id?: string;
  };
  
  type Estabelecimento = {
    nome_fantasia?: string;
    cep?: string;
    logradouro?: string;
    numero?: string;
    bairro?: string;
    complemento?: string;
    cidade?: Cidade;
    ddd1?: string;
    telefone1?: string;
    email?: string;
  };
  
  type CNPJData = {
    razao_social?: string;
    estabelecimento?: Estabelecimento;
  };
  
  const preencherFormularioComDadosCNPJ = async (
    data: CNPJData,
    inscricoesAtivas: InscricaoEstadual[]
  ) => {
    methods.setValue('Nome', data?.razao_social || '');
    methods.setValue(
      'NomeFantasia',
      data.estabelecimento?.nome_fantasia || data?.razao_social || ''
    );
  
    // Tratando Inscrição Estadual
    methods.setValue(
      'RGIE',
      inscricoesAtivas.length === 1 ? inscricoesAtivas[0].inscricao_estadual : ''
    );
  
    const cep = data.estabelecimento?.cep || '';
    methods.setValue('Cep', cep);
  
    // Se houver um CEP válido, buscar endereço e cidade em paralelo
    if (cep) {
      try {
        const [enderecoData, cidadeData] = await Promise.all([
          AddressService.getAddressByZipCode(cep),
          data.estabelecimento?.cidade?.ibge_id
            ? CitiesService.getCityCodeByIBGECode(data.estabelecimento.cidade.ibge_id)
            : null,
        ]);
  
        if (Array.isArray(enderecoData) && enderecoData.length > 0) {
          const endereco = enderecoData[0] ?? {};
          console.log('endereco.Logradouro', endereco.Logradouro);
          methods.setValue('Logradouro', endereco.CodigoTipoLograd || '');
          methods.setValue('Endereco', endereco.Logradouro || '');
          methods.setValue('Bairro', endereco.Bairro || '');
          methods.setValue('ComplementoEndereco', endereco.Complemento || '');
        } else {
          console.log('CEP não encontrado.');
        }

        console.log(cidadeData);
  
        if (cidadeData) {
          methods.setValue('CodigoCidade', cidadeData.city_cod || '');
        } else {
          methods.setValue('CodigoCidade', '');
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    } else {
      methods.setValue('CodigoCidade', '');
    }
  
    // Preenchendo os demais dados do estabelecimento
    methods.setValue('Endereco', data.estabelecimento?.logradouro || '');
    methods.setValue('NumeroEndereco', data.estabelecimento?.numero || '');
    methods.setValue('Bairro', data.estabelecimento?.bairro || '');
    methods.setValue('ComplementoEndereco', data.estabelecimento?.complemento || '');
  
    // Preenchendo Telefones
    if (data.estabelecimento?.ddd1 && data.estabelecimento?.telefone1) {
      appendPhone({
        Operacao: 'I',
        Tipo: 'Comercial',
        DDD: data.estabelecimento.ddd1,
        Numero: data.estabelecimento.telefone1,
        NumeroRamal: '',
        Principal: 'Sim',
        Descricao: '',
      });
    }
  
    // Preenchendo E-mails
    if (data.estabelecimento?.email) {
      appendEmail({
        Operacao: 'I',
        Tipo: 'Comercial',
        Email: data.estabelecimento.email,
        Principal: 'Sim',
        NFe: 'Não',
        NFSe: 'Não',
        Descricao: '',
      });
    }
  };
  

  // Adicionar novo estado
  const [isLoadingCEP, setIsLoadingCEP] = useState(false);

  const fetchAddressByZipCode = async (cep?: string) => {
    const zipCode = methods.getValues('Cep')?.replace(/\D/g, '');
    
    if (!zipCode || zipCode.length !== 8) {
      toast.error('Por favor, insira um CEP válido (8 dígitos).');
      return;
    }
  
    const enderecoAtual = methods.getValues('Endereco')?.trim();
    const bairroAtual = methods.getValues('Bairro')?.trim();
  
    if (enderecoAtual || bairroAtual) {
      const confirmacao = window.confirm(
        'Os dados existentes serão sobrescritos. Deseja continuar?'
      );
      if (!confirmacao) {
        return;
      }
    }
  
    try {
      setIsLoadingCEP(true); // Ativa o loading do CEP
      const enderecoData = await AddressService.getAddressByZipCode(zipCode);
  
      console.log('Resposta da API:', enderecoData);
  
      if (Array.isArray(enderecoData) && enderecoData.length > 0) {
        const endereco = enderecoData[0];
  
        if (!endereco) {
          toast.warning('CEP não encontrado.');
          return;
        }
  
        limparFormulario('CEP'); // Limpa os campos antes de inserir novos dados
  
        // Preenchimento seguro dos valores
        methods.setValue('Logradouro', endereco.CodigoTipoLograd || '');
        methods.setValue('Endereco', endereco.Logradouro || '');
        methods.setValue('Bairro', endereco.Bairro || '');
        methods.setValue('ComplementoEndereco', endereco.Complemento || '');
       
        try {
          if (!endereco?.CodigoCidadeIbge) {
            console.warn('Código IBGE não fornecido.');
            return;
          }
        
          const citydata = await CitiesService.getCityCodeByIBGECode(endereco.CodigoCidadeIbge);
        
          if (citydata && typeof citydata.city_cod !== 'undefined') {
            console.log('CITY CODE', citydata.city_cod);
            methods.setValue('CodigoCidade', citydata.city_cod);
          } else {
            console.warn('Cidade não encontrada para o código IBGE fornecido.');
            methods.setValue('CodigoCidade', '');
          }
        } catch (error) {
          console.error('Erro ao buscar código da cidade:', error);
          methods.setValue('CodigoCidade', '');
        }
        
        toast.success('Dados do CEP preenchidos com sucesso!', { autoClose: 2000 });
      } else {
        toast.warning('CEP não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar o CEP:', error);
      toast.error('Erro ao buscar os dados do CEP.');
    } finally {
      setIsLoadingCEP(false); // Desativa o loading do CEP
    }
  };

  const validarDocumento = async (cpfCnpj: string) => {
    // Se o CPF/CNPJ estiver vazio, limpa o erro e retorna
    if (!cpfCnpj) {
      methods.clearErrors('CPFCNPJ');
      setDocumentoVerificado(null);
      return null;
    }

    // Se o CPF/CNPJ já foi verificado, não precisa verificar novamente
    if (documentoVerificado === cpfCnpj) {
      return methods.getFieldState('CPFCNPJ').error ? true : false;
    }

    if (cpfCnpj.length !== 11 && cpfCnpj.length !== 14) {
      methods.clearErrors('CPFCNPJ');
      return null;
    }

    const existe = await EntitiesService.verifyEntityDuplicity(cpfCnpj);
    
    if (existe && !(existe instanceof Error) && existe.data.length > 0) {
      toast.warn('Entidade já cadastrada no sistema.');
      methods.setError('CPFCNPJ', { 
        type: 'manual',
        message: 'Entidade já cadastrada!' 
      });
      setDocumentoVerificado(cpfCnpj);
      return true;
    }
    
    methods.clearErrors('CPFCNPJ');
    setDocumentoVerificado(cpfCnpj);
    return false;
  };

  const handleCNPJBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if ((e.relatedTarget as HTMLElement)?.classList?.contains('MuiIconButton-root')) {
      return;
    }

    const cpfCnpj = methods.getValues('CPFCNPJ')?.replace(/\D/g, '');
    if (!isVerifying && !isFetchingCNPJ && cpfCnpj) {
      setIsVerifying(true);
      await validarDocumento(cpfCnpj);
      setIsVerifying(false);
    }
  };

  const handleSearchClick = async () => {
    const cpfCnpj = methods.getValues('CPFCNPJ')?.replace(/\D/g, '');
    if (!isFetchingCNPJ && !isVerifying && cpfCnpj) {
      setIsFetchingCNPJ(true);
      const isDuplicated = await validarDocumento(cpfCnpj);
      if (!isDuplicated) {
        await buscarDadosCNPJ();
      }
      setIsFetchingCNPJ(false);
    }
  };

  const handleCPFCNPJChange = () => {
    methods.clearErrors('CPFCNPJ');
    setDocumentoVerificado(null);
  };

  return (
    <LayoutBaseDePagina
      titulo={
        <Box
          sx={{
            whiteSpace: 'nowrap', // Evita quebra de linha
            overflow: 'hidden', // Oculta texto excedente
            textOverflow: 'ellipsis', // Adiciona reticências ao texto excedente
          }}
        >
          {id === 'nova' ? 'Nova Entidade Prospect' : nome}
        </Box>
      }
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Nova'
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          aoClicarEmSalvar={handleSubmit(onSubmit)}
          aoClicarEmVoltar={() => navigate('/entidades')}
          aoClicarEmNovo={() => navigate('/entidades/detalhe/nova')}
          isLoading={isLoading}
          isSaving={isSaving}
        />
      }
    >
      <FormProvider {...methods}>
        <Box
          margin={1}
          display='flex'
          flexDirection='column'
          component={Paper}
          variant='outlined'
        >
          <Grid2 container direction='column' padding={2} spacing={2}>
            {isLoading && (
              <Grid2 sx={{ flexGrow: 1 }}>
                <LinearProgress variant='indeterminate' />
              </Grid2>
            )}
            <Grid2>
              <Typography variant='h6'>Geral</Typography>
            </Grid2>

            {/* Código*/}
            {id !== 'nova' ? (
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='Codigo'
                  disabledValue
                  label='Código'
                />
              </Grid2>
            ) : (
              ''
            )}

            {/*Data de Cadastro*/}
            {id !== 'nova' ? (
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='DataCadastro'
                  disabledValue
                  label='Data de Cadastro'
                />
              </Grid2>
            ) : (
              ''
            )}

            {/* Tipo */}
            <RRadio
              control={control}
              name={'TipoFisicaJuridica'}
              label={'Tipo'}
              defaultValue={'Jurídica'}
              options={[
                { label: 'Física', value: 'Física' },
                { label: 'Jurídica', value: 'Jurídica' },
              ]}
            />

            {/* CNPJ/CPF */}
            <Grid2 container direction='row' spacing={2}>
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='CPFCNPJ'
                  mask={mascara}
                  disabled={isLoading}
                  label={labelCPFCNPJ}
                  adornmentIcon={
                    tipoSelecionado === 'Jurídica' 
                      ? (isFetchingCNPJ ? <CircularProgress size={20} /> : <SearchIcon />)
                      : null
                  }
                  onAdornmentClick={handleSearchClick}
                  onBlurCapture={handleCNPJBlur}
                  onChangeCapture={handleCPFCNPJChange}
                />
              </Grid2>

              {/* RG/IE */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='RGIE'
                  disabled={isLoading}
                  label={labelRGIE}
                />
              </Grid2>
            </Grid2>

            {/* Nome Completo */}
            <Grid2 container direction='row' spacing={2}>
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='Nome'
                  disabled={isLoading}
                  label={labelNomeRazao} //"Nome/Razão completo(a)"
                  uppercase={true}
                />
              </Grid2>

              {/* Nome Fantasia */}
              {tipoSelecionado === 'Jurídica' ? (
                <Grid2
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '100%',
                      md: '50%',
                      lg: '33%',
                      xl: '25%',
                    },
                  }}
                >
                  <RTextField
                    fullWidth
                    name='NomeFantasia'
                    disabled={isLoading}
                    label='Nome Fantasia'
                    uppercase={true}
                  />
                </Grid2>
              ) : (
                ''
              )}
            </Grid2>

            <Grid2
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '33%',
                  xl: '25%',
                },
              }}
            >
              <RAutoComplete
                control={control}
                isExternalLoading={isLoading}
                name='CodigoRegiao'
                label='Região'
                source='RegioesService'
                disabledValue
              />
            </Grid2>

            <Grid2 container direction='row' spacing={2}>
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RSelect
                  name='CaracteristicaImovel'
                  label='Característica do Imóvel'
                  options={[
                    { value: 1, label: 'Residencial' },
                    { value: 2, label: 'Comercial' },
                    { value: 3, label: 'Misto' },
                  ]}
                />
              </Grid2>
            </Grid2>

            {/*Status*/}
            <Grid2 container direction='row' spacing={2}>
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RSelect
                  name='CodigoStatus'
                  label='Status'
                  options={[
                    { value: '01', label: 'Ativo' },
                    { value: '06', label: 'Prospectivo' },
                    { value: '10', label: 'Ecommerce' },
                  ]}
                  disabledValue={true}
                />
              </Grid2>
            </Grid2>

            <Grid2>
              <Typography variant='h6'>Endereço Principal</Typography>
            </Grid2>

            {/* Endereço Principal*/}
            <Grid2 container direction='row' spacing={2}>
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '33%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='Cep'
                  mask={zipeCode}
                  disabled={isLoading}
                  label='CEP'
                  adornmentIcon={isLoadingCEP ? <CircularProgress size={20} /> : <SearchIcon />}
                  onAdornmentClick={fetchAddressByZipCode}
                />
              </Grid2>

              {/* Tipo de Logradouro */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '20%',
                    xl: '25%',
                  },
                }}
              >
                <RAutoComplete
                  control={control}
                  isExternalLoading={isLoading}
                  name='Logradouro'
                  label='Tipo Logradouro'
                  source='StreetTypesService'
                  // disabledValue
                />
              </Grid2>

              {/* Endereço */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '30%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='Endereco'
                  disabled={isLoading}
                  label='Endereço'
                  // disabledValue
                />
              </Grid2>

              {/* Número */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '10%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='NumeroEndereco'
                  disabled={isLoading}
                  label='Numero'
                />
              </Grid2>

              {/* Bairro */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '30%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='Bairro'
                  disabled={isLoading}
                  label='Bairro'
                />
              </Grid2>

              {/* Complemento */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '30%',
                    xl: '25%',
                  },
                }}
              >
                <RTextField
                  fullWidth
                  name='ComplementoEndereco'
                  disabled={isLoading}
                  label='Complemento'
                />
              </Grid2>

              {/* Complemento */}
              <Grid2
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '30%',
                    xl: '25%',
                  },
                }}
              >
                <RAutoComplete
                  control={control}
                  isExternalLoading={isLoading}
                  name='CodigoCidade'
                  label='Cidade/UF'
                  source='CitiesService'
                />
              </Grid2>
            </Grid2>

            {/* Texto */}
            <Grid2>
              <Typography variant='h6'>Mais informações</Typography>
            </Grid2>
            <Grid2
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '100%',
                  xl: '100%',
                },
              }}
            >
              <RTextField
                fullWidth
                variant='outlined'
                multiline
                minRows={2}
                name='Texto'
                disabled={isLoading}
                label='Texto'
              />
            </Grid2>

            <Grid2 direction='column' spacing={2}>
              {/* Accordion para Categorias */}
              <RAccordion
                fields={categoryFields}
                fieldConfig={categoriesFieldConfig}
                control={control}
                onAdd={() => appendCategory({ Codigo: '', Operacao: 'I' })}
                onRemove={onRemoveCategory}
                name='Categorias'
                groupLabel='Categorias'
                buttonLabel='Adicionar Categoria'
                alwaysOpen={id == 'nova'}
              />

              {/* Seção de Documentos */}
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Documentos Anexos</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '8px 16px 16px' }}>
                  <Box>
                    <RFileUpload
                      name="Documentos"
                      control={control}
                      accept="image/*,application/pdf"
                      multiple={true}
                      label="ANEXAR ARQUIVO"
                    />

                    {/* Lista de arquivos anexados */}
                    {arquivosAnexados.length > 0 && (
                      <Box mt={2}>
                        <Typography variant="subtitle1">Arquivos Anexados:</Typography>
                        <ul>
                          {arquivosAnexados.map((arquivo, index) => (
                            <li key={index}>{arquivo}</li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/* Accordion para Telefones */}
              <RAccordion
                fields={phoneFields}
                fieldConfig={phoneFieldConfig}
                control={control}
                onAdd={() =>
                  appendPhone({
                    Operacao: 'I',
                    Tipo: '',
                    DDD: '',
                    Numero: '',
                    NumeroRamal: '',
                    Principal: 'Não',
                    Descricao: '',
                  })
                }
                onRemove={onRemovePhone}
                name='Telefones' // Certifique-se de que o nome está correto
                groupLabel='Telefones'
                buttonLabel='Adicionar Telefone'
              />

              {/* Accordion para Endereços Eletrônicos */}
              <RAccordion
                fields={emailFields}
                fieldConfig={emailFieldConfig}
                control={control}
                onAdd={() =>
                  appendEmail({
                    Operacao: 'I',
                    Tipo: '',
                    Email: '',
                    Principal: 'Não',
                    NFe: 'Não',
                    NFSe: 'Não',
                    Descricao: '',
                  })
                }
                onRemove={onRemoveEmail}
                name='Emails' // Certifique-se de que o nome está correto
                groupLabel='Endereços Eletrônicos'
                buttonLabel='Adicionar End. Eletrônico'
              />

             
            </Grid2>

          </Grid2>
        </Box>
      </FormProvider>
    </LayoutBaseDePagina>
  );
};

export type { IFormData };
