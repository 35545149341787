import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify'; // Importação para notificar o usuário

interface Documento {
  id: string;
  Arquivo: string;
  ArquivoBase64: string;
  Operacao: 'I';
}

interface RFileUploadProps {
  name: string;
  label?: string;
  control: any;
  multiple?: boolean;
  accept?: string;
}

const MAX_FILE_SIZE_MB = 20; // Limite máximo de 20 MB

const RFileUpload: React.FC<RFileUploadProps> = ({
  name,
  label = 'Upload de Arquivo',
  control,
  multiple = false,
  accept = 'image/*,application/pdf',
}) => {
  const { fields, append, remove } = useFieldArray<{ Documentos: Documento[] }, 'Documentos'>({
    control,
    name: 'Documentos',
  });

  // Converte arquivos para Base64
  const convertToBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result as string).split(',')[1]); // Remove o prefixo base64
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    if (files.length === 0) return;

    const validFiles: File[] = [];

    files.forEach((file) => {
      const fileSizeMB = file.size / (1024 * 1024); // Converte bytes para MB
      const fileType = file.type;

      // Validação do tamanho do arquivo
      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        toast.error(`O arquivo ${file.name} ultrapassa o limite de 20 MB.`);
        return; // Não adiciona o arquivo à lista de válidos
      }

      // Validação do tipo de arquivo (somente imagens e PDFs)
      if (!['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'].includes(fileType)) {
        toast.error(`O arquivo ${file.name} não é permitido. Apenas imagens (JPG, PNG) e PDFs são aceitos.`);
        return;
      }

      validFiles.push(file);
    });

    if (validFiles.length === 0) {
      event.target.value = ''; // Reseta o input para permitir novas tentativas
      return;
    }

    const fileData: Documento[] = await Promise.all(
      validFiles.map(async (file, index) => ({
        id: `${file.name}-${index}`, // ID único
        Arquivo: file.name,
        ArquivoBase64: await convertToBase64(file),
        Operacao: 'I',
      }))
    );

    fileData.forEach((file) => append(file));

    event.target.value = ''; // Reseta o input para permitir a re-seleção do mesmo arquivo
  };

  const handleRemoveFile = (index: number) => {
    remove(index);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <Box>
          {fields.length > 0 && (
            <Box mt={2}>
              {fields.map((file, index) => (
                <Box key={file.id} display="flex" alignItems="center" gap={2}>
                  <Typography>{(file as Documento).Arquivo}</Typography>
                  <IconButton 
                    onClick={() => handleRemoveFile(index)}
                    sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
          <Box mt={2}>
            <Button 
              variant="outlined" 
              component="label" 
              startIcon={<CloudUploadIcon />}
              sx={{
                textTransform: 'none',
                color: 'rgb(25, 118, 210)',
                fontSize: '0.875rem',
                padding: '6px 16px',
                borderRadius: '4px',
                lineHeight: 1.75
              }}
            >
              {label}
              <input 
                type="file" 
                hidden 
                multiple={multiple} 
                accept={accept} 
                onChange={handleFileChange} 
              />
            </Button>
          </Box>
        </Box>
      )}
    />
  );
};

export default RFileUpload;
