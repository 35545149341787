import { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  useTheme,
  useMediaQuery,
  Link,
} from '@mui/material';

import * as yup from 'yup';

import { useAuthContext } from '../../contexts';

const loginSchema = yup.object().shape({
  userName: yup.string().required(),
  password: yup.string().required().min(3),
});

interface ILoginProps {
  children: React.ReactNode;
}

export const Login: React.FC<ILoginProps> = ({ children }) => {
  const { isAuthenticated, login } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [lembrarUsuario, setLembrarUsuario] = useState(false);

  const passwordInputRef = useRef<HTMLInputElement | null>(null);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const usuarioSalvo = localStorage.getItem('usuario');
    if (usuarioSalvo) {
      setUserName(usuarioSalvo);
      setLembrarUsuario(true);
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      setPassword('');
      passwordInputRef.current && (passwordInputRef.current.value = '');

      const usuarioSalvo = localStorage.getItem('usuario');
      if (!usuarioSalvo) {
        setUserName('');
      }
    }
  }, [isAuthenticated]);

  const handleSubmit = () => {
    setIsLoading(true);

    loginSchema
      .validate({ userName, password }, { abortEarly: false })
      .then((dadosValidados) => {
        if (lembrarUsuario) {
          localStorage.setItem('usuario', userName);
        } else {
          localStorage.removeItem('usuario');
        }

        login(dadosValidados.userName, dadosValidados.password).finally(() => {
          setIsLoading(false);
        });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach((error) => {
          if (error.path === 'userName') {
            setUserNameError(error.message);
          } else if (error.path === 'password') {
            setPasswordError(error.message);
          }
        });
      });
  };

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Card sx={{ width: mdUp ? 400 : 300 }}>
        <CardContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display="flex" flexDirection="column" gap={2} alignItems="center">
              <Avatar
                sx={{
                  height: theme.spacing(8),
                  width: theme.spacing(20),
                }}
                src="https://ideogram.ai/assets/image/lossless/response/ktaEdkxCS-WbSUlu3lulxA"
              />

              <Box
                component="img"
                sx={{
                  width: smDown ? '50%' : '40%',
                  maxWidth: '100px',
                  height: 'auto',
                  borderRadius: '8px',
                }}
                src="https://riosoft.com.br/wp-content/uploads/2024/01/Riosoft-Logo-512x512-1.png"
                alt="Logo Riosoft"
              />

              <Divider sx={{ width: '100%' }} />

              <Typography variant="h6" align="center">
                Identifique-se
              </Typography>

              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Usuário"
                value={userName}
                disabled={isLoading}
                error={!!userNameError}
                helperText={userNameError}
                autoComplete="off"
                onChange={(e) => {
                  const novoValor = e.target.value
                    .toUpperCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                  setUserName(novoValor);
                  setUserNameError('');
                }}
              />

              <TextField
                fullWidth
                variant="outlined"
                label="Senha"
                type="password"
                value={password}
                disabled={isLoading}
                error={!!passwordError}
                helperText={passwordError}
                autoComplete="off"
                inputRef={passwordInputRef}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={lembrarUsuario}
                    onChange={(e) => setLembrarUsuario(e.target.checked)}
                  />
                }
                label="Lembrar usuário"
                disabled={isLoading} // 🔥 Aqui desabilita quando loading
              />
            </Box>

            <CardActions sx={{ flexDirection: 'column', gap: 1 }}>
              <Box width="100%" display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  disabled={isLoading}
                  type="submit"
                  endIcon={
                    isLoading ? <CircularProgress variant="indeterminate" color="inherit" size={20} /> : undefined
                  }
                >
                  Entrar
                </Button>
              </Box>

              {/* Esqueceu a senha */}
              <Box width="100%" display="flex" justifyContent="center">
                <Link
                  href="https://conabconserbombasltda.suport.systems/#/login"
                  target="_blank"
                  underline="hover"
                  sx={{
                    pointerEvents: isLoading ? 'none' : 'auto', // 🔥 Evita clique
                    color: isLoading ? theme.palette.grey[500] : undefined, // 🔥 Muda a cor se loading
                    //textDecoration: isLoading ? 'none' : 'underline',
                  }}
                >
                  Esqueceu a senha?
                </Link>
              </Box>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};
