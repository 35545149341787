export const Environment = {
  /**
     * Define a quantidade de linhas a ser carregada por padrão nas listagens
     */
  LIMITE_DE_LINHAS: 10000,
  /**
     * Placeholder exibido nas inputs
     */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
     * Texto exibido quando nenhum registro é encontrado em uma listagem
     */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado. Realize uma nova busca',
  /**
     * Url base de consultado dos dados dessa aplicação
     */
  URL_BASE: 'https://api.conabbombas.com.br/',
};
